<template>
    <!-- Component: organisms/contact-info-card -->
    <info-card :updated="!!modificationMonth" :modification-month="modificationMonth" :contact="$props" :cardtoolsize="cardtoolsize">
        <div class="card-content" :class="{'is-leadership': agencyHead}">
            <div class="section">
                <div class="content">

                    <div class="media media__mobile-only">
                        <div class="media-centered">
                            <figure class="image">
                                <img v-if="photo" class="card__info__img" :src="photo.src ? photo.src : photo.url" :alt="photo.alt || (firstName + ' ' + lastName + ' headshot.')">
                                <img v-else src="@/assets/svg/default-user.svg" alt="Photo not on file." class="card__info__img no-mobile">
                            </figure>
                        </div>
                    </div>

                    <p class="card__info__label">Contact Name</p>

                    <span v-if="(isactive == true) && (agencyHead && agencyHead != 'false')">
                        <p class="card__info__title">
                            <span class="name">
                                {{ toTitleCase(fullNameText) }}
                            </span>
                            <leadership-marker/>
                        </p>
                    </span>
                    <p class="card__info__title isactive" v-else-if="isactive && isactive == true">{{ toTitleCase(fullNameText) }}</p>
                    <a :href="url ? url : contactUrl" class="contact-is-leader" v-else-if="agencyHead && agencyHead != 'false'">
                        <p class="card__info__title">
                            <span class="name">
                                {{ toTitleCase(fullNameText) }}
                            </span>
                            <leadership-marker/>
                        </p>
                    </a>
                    <a :href="url ? url : contactUrl" class="card__info__title" v-else>{{ toTitleCase(fullNameText) }}</a>

                    <div class="tag-container">
                        <p class="card__info__label card__info__label__with-tag" v-if="agency">Agency</p>
                        <tag-agency :tag-text="agency.name" :tag-url="agency.url" v-if="agency"/>
                    </div>

                    <div class="tag-container">
                        <div class="" v-for="program in programs" v-if="programs">
                            <p class="card__info__label card__info__label__with-tag">Program</p>
                            <tag-budget :tag-text="getProgramName(program)" :tag-url="program.url"/>
                        </div>
                    </div>

                    <template v-if="position && position.displayTitle">
                        <div class="tag-container">
                            <p class="card__info__label card__info__label__with-tag">Position</p>
                            <tag-role
                                :tag-text="position.displayTitle"
                                :tag-url="position.PositionRef && position.PositionRef.url ? position.PositionRef.url : position.url"
                            />
                        </div>
                    </template>
                    <template v-else>
                        <div class="tag-container">
                            <p class="card__info__label card__info__label__with-tag" v-if="position">Position</p>
                            <tag-role
                                v-if="position"
                                :tag-text="positionName"
                                :tag-url="position.PositionRef && position.PositionRef.url ? position.PositionRef.url : position.url"
                            />
                        </div>
                    </template>

                    <nav class="level mb-10" v-if="(agency && agency.location && agency.location.address) && (agencyHead && agencyHead != 'false')">
                        <div class="level-item level-item__full-width">
                            <div>
                                <p class="card__info__label">Address</p>
                                <a :href="'https://www.google.com/maps/search/?api=1&query=' + agency.location.address" target="_blank" class="title">{{ agency.location.address }}</a>
                            </div>
                        </div>
                    </nav>

                    <nav class="level" v-if="city || hireDate || salary || last_salary || budgetEntity || (position && position.seniority) || employeesOversees || (position && position.displayTitle)">
                        <div class="level-item" v-if="position && position.displayTitle">
                            <div>
                                <p class="card__info__label">Position Title</p>
                                <p class="title">{{ position.displayTitle }}</p>
                            </div>
                        </div>
                        <div class="level-item" v-if="city && exempt != true">
                            <div>
                                <p class="card__info__label">City</p>
                                <p class="title">{{ city }}</p>
                            </div>
                        </div>
                        <div class="level-item" v-if="hireDate">
                            <div>
                                <p class="card__info__label">State Hire Date</p>
                                <p class="title">{{ hireDate }}</p>
                            </div>
                        </div>
                        <div class="level-item" v-if="salary">
                            <div>
                                <p class="card__info__label">Salary</p>
                                <p class="title">${{ priceFormat(salary) }}</p>
                            </div>
                        </div>
                        <div class="level-item" v-if="last_salary">
                            <div>
                                <p class="card__info__label">Salary</p>
                                <p class="title">${{ priceFormat(last_salary) }}</p>
                            </div>
                        </div>
                        <div class="level-item" v-if="budgetEntity">
                            <div>
                                <p class="card__info__label">Budget Entity</p>
                                <p class="title">{{ budgetEntity }}</p>
                            </div>
                        </div>
                        <div class="level-item" v-if="position && position.seniority">
                            <div>
                                <p class="card__info__label">Seniority</p>
                                <p class="title">{{ position.seniority }}</p>
                            </div>
                        </div>
                        <div class="level-item" v-if="employeesOversees">
                            <div>
                                <p class="card__info__label">Employees Overseen</p>
                                <p class="title">{{ employeesOversees }}</p>
                            </div>
                        </div>
                        <div class="level-item" v-if="position && position.flairOrganization">
                            <div>
                                <p class="card__info__label">
                                    <Tooltip :tooltipText="definitions && definitions['FLAIR'] && definitions['FLAIR'].definition ? definitions['FLAIR'].definition : 'Loading...'">
                                        <span class='has-tooltip'>
                                            FLAIR
                                        </span>
                                    </Tooltip>
                                     Organization
                                </p>
                                <p class="title">{{ position && position.flairOrganization }}</p>
                            </div>
                        </div>
                    </nav>
                </div>

                <div class="media">
                    <div class="media-right">
                        <figure class="image">
                            <img v-if="photo" class="card__info__img" :src="photo.src ? photo.src : photo.url" :alt="photo.alt || (firstName + ' ' + lastName + ' headshot.')">
                            <img v-else src="@/assets/svg/default-user.svg" alt="Photo not on file.">
                        </figure>
                    </div>
                </div>
            </div>

            <div class="section__alt" v-if="linkedin || phone || phoneManual || cellPhone || twitter || email || displayEmail || facebook">
                <div class="columns">
                    <div class="column">
                        <div class="contact-block">

                            <div class="mb-15">

                                <p class="card__info__label mb-0" v-if="phone || phoneManual || cellPhone || email || displayEmail">Contact Information</p>

                                <button class="vcard" @click="saveFile()" aria-label="Save to device contacts">
                                    <font-awesome-icon :icon="faAddressCard"></font-awesome-icon>
                                    <p class="underline">Download contact card to device</p>
                                </button>

                                <div class="social-list" v-if="phone || phoneManual || cellPhone || email || displayEmail">
                                    <template v-if="phone || phoneManual">
                                        <div class="social-list__item" v-if="phoneManual && phoneManual.length >= 7">
                                            <contact-link icon="phone" :url="'tel:' + phoneManual">{{ phoneManual }}</contact-link>
                                        </div>
                                        <div class="social-list__item" v-else-if="phone && phone.length >= 7" >
                                            <contact-link icon="phone" :url="phone">{{ phone }}</contact-link>
                                        </div>
                                    </template>
                                    <div class="social-list__item" v-if="cellPhone && cellPhone.length >= 7">
                                        <contact-link icon="phone" :url="'tel:' + cellPhone">{{ cellPhone }}</contact-link>
                                    </div>
                                    <div class="social-list__item" v-if="email && !displayEmail">
                                        <contact-link icon="email" :url="'mailto:' + email">{{ email }}</contact-link>
                                    </div>
                                    <div class="social-list__item" v-if="displayEmail">
                                        <contact-link icon="email" :url="'mailto:' + displayEmail">{{ displayEmail }}</contact-link>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <p class="card__info__label mb-0" v-if="twitter || facebook || linkedin">Social Media</p>
                                <div class="social-list" v-if="twitter || facebook || linkedin">
                                    <div class="social-list__item" v-if="linkedin">
                                        <contact-link icon="linkedin" :url="linkedin">LinkedIn</contact-link>
                                    </div>
                                    <div class="social-list__item" v-if="facebook">
                                        <contact-link icon="facebook" :url="facebook">Facebook</contact-link>
                                    </div>
                                    <div class="social-list__item" v-if="twitter">
                                        <contact-link icon="twitter" :url="twitter">Twitter</contact-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="column mw-100" v-if="executiveAssistantPositions" v-for="(executiveAssistantPosition, i) in executiveAssistantPositions">
                        <template v-if="executiveAssistantContacts && (typeof executiveAssistantContacts[i] !== 'undefined') && executiveAssistantContacts[i]">
                            <p class="card__info__label mb-10" v-if="position.isAssistant == true">Assistant to</p>
                            <p class="card__info__label mb-10" v-else>Executive Assistant</p>

                            <tag-contact
                                :tagText="executiveAssistantContacts[i].firstName + ' ' + executiveAssistantContacts[i].lastName"
                                :tagUrl="executiveAssistantContacts[i].url"
                                :assistant="true"
                            />
                            <tag-role
                                :tag-text="executiveAssistantPosition.name"
                                :tag-url="executiveAssistantPosition.url"
                                :assistant="true"
                            />
                            <div class="contact-info" v-if="executiveAssistantContacts[i].phoneNumber || executiveAssistantContacts[i].email">
                                <span class="contact-info__item" v-if="executiveAssistantContacts[i].phoneNumber != null">
                                    <a :href="'tel: ' + executiveAssistantContacts[i].phoneNumber">
                                        <img src="@/assets/svg/social-media/phone.svg" alt="Executive Assistant's Phone Number" />
                                        <p>{{ executiveAssistantContacts[i].phoneNumber }}</p>
                                    </a>
                                </span>
                                <span class="contact-info__item" v-if="executiveAssistantContacts[i].email != null">
                                    <a :href="'mailto:' + executiveAssistantContacts[i].email">
                                        <img src="@/assets/svg/social-media/email.svg" alt="Executive Assistant's Email" />
                                        <p class="ver-desk">{{ executiveAssistantContacts[i].email }}</p>
                                        <p class="ver-mobile">Email</p>
                                    </a>
                                </span>
                            </div>
                        </template>
                    </div>
                </div>

                <div class="columns" v-if="biography || interestingFacts">
                    <div class="column" :class="{'is-three-quarters': interestingFacts}" v-if="biography">
                        <p class="card__info__label">Contact Biography</p>
                        <p class="card__info__description" v-html="biography"></p>
                    </div>
                    <div class="column" v-if="interestingFacts">
                        <fact-box title="Interesting facts:" :content="interestingFacts"></fact-box>
                    </div>
                </div>
            </div>
            <!--
            <div class="section">
                <div class="content">
                    <div class="columns">
                        <div class="column">
                            <button class="button is-pulled-right export-button" @click.prevent="exportLocation">Generate Report</button>
                        </div>
                    </div>
                </div>
            </div>
            -->
        </div>
    </info-card>
    <!-- End Component: organisms/contact-info-card -->
</template>

<script>
    import FactBox from "./fact-box.vue";
    import InfoCard from "./info-card";
    import TagAgency from "./tag--agency";
    import TagRole from "./tag--role";
    import TagBudget from "./tag--budget";
    import TagContact from "./tag--contact";
    import LeadershipMarker from "./leadership-marker";
    import ContactLink from "./contact-link";
    import { resizeImage, removeCanvases } from '@/helpers/contact';
    import { computed } from "vue";
    import { useStore } from "vuex";
    import { contactFullName } from "@/helpers/objects";
    import { priceFormat, toTitleCase } from "@/helpers/filter";
    import Tooltip from "./tooltip";
    import AppButton from "./app-button.vue";
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { faAddressCard } from '@fortawesome/pro-solid-svg-icons';
    import vCardsJS from "vcards-js";
    import { saveAs } from "file-saver";

    export default {
        name: 'ContactInformationCard',
        components: {
            AppButton,
            InfoCard,
            TagAgency,
            TagRole,
            TagBudget,
            TagContact,
            LeadershipMarker,
            ContactLink,
            FactBox,
            Tooltip,
            FontAwesomeIcon
        },
        props: [
            "nameLinked", "url", "contentId", "locationId",
            "lastName", "firstName", "goesBy", "title", "photo", "email", "displayEmail", "phone", "cellPhone", "phoneManual",
            "linkedin", "twitter", "facebook", "aboutMe", "employeesOversees", "middleName", "city", "age", "gender",
            "agencyHead", "biography", "interestingFacts", "modificationMonth", "locationId",
            "position", "agency", "contactUrl", "cardtoolsize", "positionRole", "exempt",
            "hireDate", "salary", "budgetEntity", "city", "programs", "isactive", "last_salary",
            "executiveAssistantContacts", "executiveAssistantPositions"
        ],
        mounted() {
            let photo = this.photo;
            if (photo) {
                resizeImage(photo, photo.boundingBox, 'card__info__img');
            }

            if (document.querySelector('.vcard')) {
                const vcard = document.querySelector('.vcard__container');
                const cardTools = document.querySelector('.card__tools');
                cardTools.appendChild(vcard);
            }
        },
        updated() {
            removeCanvases('card__info__img');
            let photo = this.photo;
            if (photo) {
                resizeImage(photo, photo.boundingBox, 'card__info__img');
            }
        },
        setup(props) {
            const store = useStore();
            const definitions = computed(() => store.getters.definitions);
            const fullNameText = computed(() => contactFullName(props, true));
            const positionName = computed(() => {
                let name = props.position.PositionRef && props.position.PositionRef.name || props.position.title;
                if (props.positionRole) {
                    name += ' (' + props.positionRole + ')';
                }
                return name;
            });

            return {
                fullNameText, positionName, priceFormat,
                toTitleCase, definitions, faAddressCard: faAddressCard
            };
        },
        methods: {
            getProgramName: function(program) {
                let name = program.name;
                if (program.programCategory && program.programCategory.name) {
                    name += ' (' + program.programCategory.name + ')';
                }
                return name;
            },
            exportLocation: function(event) {
                event.preventDefault();
                event.stopPropagation();
                let locationId = this.locationId;
                window.open('/api/export/' + locationId, '_blank');
            },
            saveFile: function() {
                var FileSaver = require('file-saver');
                var vCard = vCardsJS(); // https://github.com/enesser/vCards-js

                vCard.firstName = toTitleCase(this.firstName);

                if (this.goesBy) {
                    vCard.middleName = "\"" + this.goesBy + "\"";
                }

                vCard.lastName = toTitleCase(this.lastName);

                vCard.organization = toTitleCase(this.agency.name);

                vCard.workPhone = this.phone;
                vCard.email = this.email.toString();
//                vCard.birthday = new Date(1985, 0, 1);
                vCard.title = toTitleCase(this.position.title);


                if (this.position.displayTitle) {
                    vCard.title = toTitleCase(this.position.displayTitle)
                } else {
                    vCard.title = toTitleCase(this.positionName)
                }

                // dont think this works
                if (this.agencyHead) {
                    vCard.role = "This contact is in a leadership position (at time of import: " + new Date() + ")";
                }

                if (this.photo) {
                    if (this.photo.src) {
                    vCard.photo.attachFromUrl(
                        this.photo.src, "JPEG"
                    );
                } else if (this.photo.url) {
                    vCard.photo.attachFromUrl(
                        this.photo.url, "JPEG"
                    );
                }
                }

                if (this.phoneManual) {
                    vCard.workPhone = this.phoneManual;
                } else {
                    vCard.workPhone = this.phone;
                }

                if (this.cellPhone) {
                    vCard.cellPhone = this.cellPhone;
                }

                if (this.displayEmail) {
                    vCard.email = this.displayEmail.toString();
                } else {
                    vCard.email = this.email;
                }

                //vCard.birthday = new Date(1985, 0, 1);

                if (this.position.displayTitle) {
                    vCard.title = toTitleCase(this.position.displayTitle);
                } else {
                    vCard.title = toTitleCase(this.position.title);
                }

                //vCard.address = this.agency.location.address;
                if (this.agency.location) {
                    vCard.workAddress.label = 'Agency Address';
                    vCard.workAddress.street = this.agency.location.address;
                    if (this.city) {
                        vCard.workAddress.city = this.city;
                    }
                    vCard.workAddress.stateProvince = 'FL';
                    //vCard.workAddress.postalCode = '32399';
                    vCard.workAddress.countryRegion = 'United States of America';
                }
                if (this.facebook) {
                    vCard.socialUrls['facebook'] = this.facebook.toString();
                }
                if (this.linkedin) {
                    vCard.socialUrls['linkedIn'] = this.linkedin.toString();
                }
                if (this.twitter) {
                    vCard.socialUrls['twitter'] = this.twitter.toString();
                }
                vCard.url = window.location.href;

                vCard.note = "Imported via myGovGuide®";

                // dont think these work
                vCard.timezone = "America/New_York"
                vCard.rev = new Date(); // revision date

                var content = vCard.getFormattedString();

                content = content.replace(/SOCIALPROFILE;CHARSET=UTF-8;/gm, "SOCIALPROFILE;");

                var file = new File([content], toTitleCase(this.firstName) + "-" + toTitleCase(this.lastName) + "_" + "vCard.vcf", {type: "text/vcard;charset=utf-8"});
                FileSaver.saveAs(file);
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/_variables.scss";
    @import "../../assets/scss/mixins/_bemify.scss";

    .tag-container {
        margin-bottom: 20px;
    }

    .section__alt {
        margin-top: 15px;
    }

    .card-content {
        &.is-leadership {
            //background-color: $blue--lighter;
            border: 3px solid $blue--med;
        }
    }

    .contact-is-leader {
        .card__info__title {
            display: inline-block;
            float: left;
            margin-bottom: 15px;
        }
    }

    @include block('card') {
        @include element('info') {
            @include element('label') {
                display: block;
                width: 100%;
                @include element('with-tag') {
                    margin-bottom: 10px !important;
                    width: 100%;
                    padding-bottom: 5px;
                }
            }
            @include element('title') {
                display: block;
                margin-bottom: 15px;
                font-weight: 600;
                line-height: 1.125;
                font-size: 2em;
                font-family: $header;
                text-decoration: none;
                display: flex;
                span.name {
                    float: left;
                    text-decoration: underline;
                }
                &:hover, &:focus, &:active {
                    text-decoration: underline;
                }
                &.isactive {
                    &:hover, &:focus, &:active {
                        text-decoration: none;
                    }
                }
            }
        }
        .media {
            display: contents;
            @media screen and (max-width: 991px) {
                display: none;
            }
            .media-right .image {
                max-width: 275px;
            }
            &.media__mobile-only {
                display: none;
                max-width: 275px;
                margin: 0 auto;
                padding-bottom: 15px;
                @media screen and (max-width: 991px) {
                    display: block;
                }
            }
            .image canvas{
                border-radius: 100%;
                height: auto;
                width: 100%;
            }
        }
        .gov-tag {
            display: inline-block;
        }
    }

    .contact-is-leader {
        display: flow-root;
        h1 {
            display: inline-block;
            float: left;
            margin-right: 5px;
        }
    }

    .contact-block {
        margin-bottom: 30px;
        .card__info__label {
            margin-bottom: 15px !important;
        }
        .columns {
            margin-bottom: 5px !important;

            &:last-of-type {
                margin-bottom: 0px !important;
            }
            .column {
                padding-bottom: 0px;
                padding-top: 0px;
                padding-left: 0px;
            }
        }
    }
    .level {
        margin-bottom: 15px;
        justify-content: start;
        display: flow-root;
        .level-item {
            display: inline-flex !important;
            max-width: 200px;
            width: auto;
            margin-right: 40px;
            margin-bottom: 15px;
            .title {
                font-size: 16px;
            }
            &.level-item__full-width {
                max-width: inherit;
            }
        }
    }
    .mb-0, .contact-block .card__info__label.mb-0 {
        margin-bottom: 0px !important;
    }
    .mb-10 {
        margin-bottom: 10px !important;
    }
    .mb-15 {
        margin-bottom: 15px !important;
    }
    @include block('contact-info') {
        @include element('item') {
            margin-right: 15px;
            display: inline-block;
            a {
                display: inline-block;
            }
            img {
                margin-right: 5px;
            }
            p {
                text-decoration: underline;
                color: $blue--med;
                transition: all 0.3s ease-in-out;
                font-size: 14px;
                position: relative;
                top: -15px;
                margin-bottom: 0px;
                &:hover, &:focus, &:active {
                    color: $blue--dark;
                }
            }
            img, p {
                display: inline-block;
            }
        }
    }
    a.title {
        color: $blue--med;
        transition: all 0.5s ease-in-out;
        &:hover, &:focus, &:active {
            color: $blue--dark;
        }
    }
    @include block('social-list') {
        @include element('item') {
            display: inline-block;
            margin-right: 15px;
            button.contact-link {
                position: relative;
                left: -9px;
            }
        }
    }
    .no-mobile {
        @media screen and (max-width: 768px) {
            display: none;
        }
    }

    .export-button {
        &:focus, &:active {
            color: $white !important;
        }
    }

    .vcard {
        background-color: transparent;
        color: $white;
        font-weight: bold;
        border: none;
        padding: 0px;
        border-radius: 6px;
        cursor: pointer;
        width: 250px;
        span {
            margin-left: 5px;
        }
        svg {
            font-size: 24px;
            color: $blue--dark;
        }


    .aria-hidden {
        display: none;
    }

    .mw-100 {
        max-width: 100%;
    }

    .underline {
        text-decoration: underline;
        position: relative;
        top: 4px;
        left: -5px;
    }

    .fa-address-card {
        float: left;
        padding-right: 5px;
        font-size: 24px;
        color: $primary;
    }
}
</style>
