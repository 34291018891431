<template>
    <!-- Component: organisms/social-media-block -->
    <general-container :customClass="customClass" :title="title" :isLocked="isLocked">

        <div class="card social-media-block__card">
            <div class="card-content">

                <div class="media">
                    <div class="media-left">
                        <figure class="image">
                            <img :src="image" :alt="name + '\'s Twitter profile picture.'" v-if="type === 'twitter'">
                            <img :src="image" :alt="name + '\'s Facebook profile picture.'" v-if="type === 'facebook'">
                            <img :src="image" :alt="name + '\'s LinkedIn profile picture.'" v-if="type === 'linkedin'">
                        </figure>
                    </div>
                    <div class="media-content">
                        <p class="title is-4">{{ name }}</p>

                        <p class="subtitle is-6" v-if="type === 'linkedin'">{{ handle }}</p>
                        <p class="subtitle is-6" v-else>@{{ handle }}</p>

                        <a :href="url" target="_blank" class="button button--twitter" v-if="type === 'twitter'">{{ buttonLabel }}</a>
                        <a :href="url" target="_blank" class="button button--facebook" v-else-if="type === 'facebook'">{{
                                buttonLabel
                            }}</a>
                        <a :href="url" target="_blank" class="button button--linkedin" v-else-if="type === 'linkedin'">{{
                                buttonLabel
                            }}</a>
                    </div>
                </div>

                <time class="time" :datetime="date">{{ date }}</time>

                <div class="content" v-html="content"></div>

                <span class="more">
                    <a :href="postUrl" target="_blank" class="more--twitter" v-if="type === 'twitter'">View on Twitter</a>
                    <a :href="postUrl" target="_blank" class="more--facebook" v-else-if="type === 'facebook'">View on Facebook</a>
                    <a :href="postUrl" target="_blank" class="more--linkedin" v-else-if="type === 'linkedin'">View on Twitter</a>

                    <img class="external" src="@/assets/svg/external-link.svg" alt="This link leaves this site"/>
                </span>
            </div>
        </div>

    </general-container>
    <!-- End Component: organisms/social-media-block -->
</template>

<script>
    import GeneralContainer from "./general-container"

    export default {
        name: 'SocialMediaBlock',
        props: [
            'type', 'title', 'customClass',
            'image', 'name', 'handle', 'date', 'url', 'buttonLabel', 'content', 'postUrl'
        ],
        components: {
            GeneralContainer
        },
        data() {
            return {
                isLocked: false
            }
        },
        computed: {
            returnLargerTwitterPic() {
                return this.image;
            }
        },
        methods: {
            formatDate: function(timestamp) {
                return moment.unix(timestamp).format('MM/DD/YYYY h:mm a')
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/_variables.scss";
    @import "../../assets/scss/mixins/_bemify.scss";

    p, time, .content {
        color: $blue--dark;
    }

    .image {
        width: 95px;
        position: relative;
        top: 10px;
        img {
            border-radius: 20em;
            max-width: 95px;
            border: 2px solid #fff;
            box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
        }
    }

    .subtitle {
        margin-bottom: 5px !important;
    }

    .time {
        margin-bottom: 15px;
        display: block;
    }

    .content {
        margin-bottom: 15px;
    }

    .more {
        float: right;

        a {
            float: left;
        }
    }

    .external {
        float: right;
        margin-left: 5px;
    }

    @include block('social-media-block') {
        @include element('card') {
            background-color: transparent;
            box-shadow: none;
            .card-content {
                padding: 0px;
            }
        }
    }

    @include block('button') {
        font-weight: bold;
        text-decoration: none;
        transition: $animate--long;
        &:hover, &:active, &:focus {
            text-decoration: none;
        }
        @include modifier('twitter') {
            background-color: $twitter;
            &:hover, &:active, &:focus {
                background-color: darken($twitter, 10%);
            }
        }
        @include modifier('facebook') {
            background-color: $facebook;
            &:hover, &:active, &:focus {
                background-color: darken($facebook, 10%);
            }
        }
        @include modifier('linkedin') {
            background-color: $linkedin;
            &:hover, &:active, &:focus {
                background-color: darken($linkedin, 10%);
            }
        }
    }
</style>

