<template>
    <!-- Component: organisms/agency-info-card -->
    <info-card :agency="$props">
        <div class="card-content">
            <div class="section">
                <div class="content">

                    <p class="card__info__label top">Agency</p>
                    <div class="media mobile-media" v-if="logo && logo.src">
                        <div class="media-center">
                            <figure class="image">
                                <img :src="logo.src" :alt="logo.alt ? logo.alt : ''"/>
                            </figure>
                        </div>
                    </div>

                    <p class="card__info__title" v-if="name">{{ titleize(name) }}</p>

                    <div class="columns">
                        <div class="column" v-if="type">
                            <p class="card__info__label">Agency Type</p>
                            <p class="card__info__data">{{ type }}</p>
                        </div>

                        <div class="column hide-until-loaded" id="hide-until-loaded">
                            <p class="card__info__label">
                                <Tooltip :tooltipText="definitions && definitions['Operating Budget'] && definitions['Operating Budget'].definition ? definitions['Operating Budget'].definition : 'Loading...'">
                                    <span class='has-tooltip'>
                                        Operating Budget
                                    </span>
                                </Tooltip>
                            </p>
                            <p class="card__info__data">${{ priceFormat(totalAppropriation) }}</p>
                        </div>
                    </div>

                    <div class="columns">
                        <!-- #28fqr73: slice to prevent more than one agency head from being able to populate -->
                        <template v-if="leadership" v-for="leader in leadership.slice(0, 1)">
                            <div class="column" v-if="leader.agencyHead == true">
                                <p class="card__info__label mb-5">Agency Head</p>
                                <p class="card__info__data">
                                    <a :href="leader.url" class="gov-tag tag__contact">
                                        <font-awesome-icon :icon="iconContact" aria-label="Contact"></font-awesome-icon>
                                        <span class="tag__text" v-text="getFullNameText(leader)"></span>
                                    </a>
                                </p>
                            </div>
                        </template>

                        <div class="column">
                            <p class="card__info__label">Total Employees</p>
                            <p class="card__info__data">{{ totalEmployees }}</p>
                        </div>
                    </div>

                    <div class="columns">
                        <div class="column" v-if="location && location.address">
                            <p class="card__info__label">Address</p>
                            <p class="card__info__data">
                                <a :href="'https://www.google.com/maps/search/?api=1&query=' + location.address" target="_blank">{{
                                        location.address
                                    }}</a>
                            </p>
                        </div>

                        <div class="column" v-if="url && url.href">
                            <p class="card__info__label">Web Address</p>
                            <p class="card__info__data">
                                <a :href="url.href" target="_blank">{{ url.href }}</a>
                            </p>
                        </div>
                    </div>

                    <div v-if="twitter || facebook || linkedin || youtube">
                        <p class="card__info__label">Social Media</p>
                        <div class="social-list">
                            <div class="social-list__item" v-if="twitter">
                               <contact-link icon="twitter" :url="twitter">Twitter</contact-link>
                            </div>
                            <div class="social-list__item" v-if="linkedin">
                                <contact-link icon="linkedin" :url="linkedin">LinkedIn</contact-link>
                            </div>
                            <div class="social-list__item" v-if="facebook">
                                <contact-link icon="facebook" :url="facebook">Facebook</contact-link>
                            </div>
                            <div class="social-list__item" v-if="youtube">
                              <contact-link icon="youtube" :url="youtube">YouTube</contact-link>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="media desktop-media" v-if="logo && logo.src">
                    <div class="media-right">
                        <figure class="image card__image">
                            <img :src="logo.src" :alt="logo.alt ? logo.alt : ''"/>
                        </figure>
                    </div>
                </div>
            </div>

            <div class="section__alt mb-30" v-if="mission">
                <p class="card__info__label">Agency Mission</p>
                <p class="card__info__description" v-html="mission"/>
            </div>

            <div class="section__alt">
                <p class="card__info__label">Agency Description</p>
                <p class="card__info__description" v-html="purpose"/>
<!-- #1merk38: Remove "Read More about this agency" link on agency page
                <read-more-link
                    v-if="readMoreLink?.href" :url="readMoreLink.href" :contents="readMoreLink.name"
                    target="_blank"
                />
-->
            </div>

            <a class="readmore-link" v-if="readMoreLink && (vision || info || goals || objectives)" @click.prevent="showModal = true">{{ readMoreLink.name }}</a>

            <agency-readmore-modal
                v-if="showModal"
                @close="showModal = false"
                @created="newModalCreated"
                :agencyName="name"
                :vision="vision"
                :info="info"
                :goals="goals"
                :objectives="objectives"
            />

        </div>
    </info-card>
    <!-- End Component: organisms/agency-info-card -->
</template>

<script>
    import { onMounted, reactive, ref, computed } from 'vue';
    import { useStore } from "vuex";
    import RequestHandler from "@/handler/RequestHandler";
    import InfoCard from "./info-card";
    import ReadMoreLink from "./read-more-link";
    import ContactLink from "./contact-link";
    import AgencyReadmoreModal from "./agency-readmore-modal";
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { faCrown, faUser } from '@fortawesome/pro-light-svg-icons';
    import { toTitleCase, capitalize, priceFormat } from "@/helpers/filter";
    import Preloader from "@/alivue/components/preloader";
    import Tooltip from "./tooltip";

    export default {
        name: 'AgencyInformationCard',
        components: {
            InfoCard,
            ReadMoreLink,
            ContactLink,
            AgencyReadmoreModal,
            FontAwesomeIcon,
            Preloader,
            Tooltip
        },
        props: {
            name: String,
            type: String,
            location: Object,
            locationId: Number,
            contentId: Number,
            url: Object,
            mission: String,
            purpose: String,
            readMoreLink: Object,
            logo: Object,
            linkedin: String,
            twitter: String,
            facebook: String,
            youtube: String,
            vision: String,
            info: String,
            goals: String,
            objectives: String,
            operatingBudget: Number,
            totalEmployees: String,
            leadership: Object
        },
        setup(props) {
            const showModal = ref(false);
            const loading = ref(true);
            const budgets = ref([]);
            const totalAppropriation = ref(0);
            const store = useStore();
            const definitions = computed(() => store.getters.definitions);
            let readMoreLink = props.readMoreLink

            if (!readMoreLink) {
                readMoreLink = {};
            }

            if (!readMoreLink.name) {
                readMoreLink.name = "Read About The Goals and Objectives";
            }

            const newModalCreated = () => {
                showModal.value = false;
            };

            const unHide = () => {
                document.getElementById('hide-until-loaded').classList.remove('hide-until-loaded');
            };

            onMounted(() => {
                if (props.contentId) {
                    RequestHandler.loadAgencyBudgets(props.contentId, 100, true).then(({items}) => {
                        totalAppropriation.value = items.reduce((acc, item) => acc + item.appropriationAmount, 0);
                        budgets.value = items;

                        if (items.length > 0) {
                            loading.value = false;
                            if (document.getElementById('hide-until-loaded')) {
                                unHide();
                            }
                        }
                    }).catch(error => {
                        loading.value = false;
                        if (document.getElementById('hide-until-loaded')) {
                            unHide();
                        }
                        console.error(error);
                    });
                } else {
                    loading.value = false;
                    if (document.getElementById('hide-until-loaded')) {
                        unHide();
                    }
                }
            });

            return reactive({
                readMoreLink,
                newModalCreated,
                budgets,
                totalAppropriation,
                loading,
                priceFormat,
                showModal,
                definitions,
            });
        },
        methods: {
            titleize: function(item) {
                return item.toLowerCase().replace(/(?:^|\s|-)\S/g, x => x.toUpperCase());
            },
            getFullNameText(contact) {
                return capitalize(contact.firstName)
                    + (contact.middleName ? ' ' + capitalize(contact.middleName) : '')
                    + ' ' + capitalize(contact.lastName);
            }
        },
        data() {
            return {
                iconContact: faUser
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/_variables.scss";
    @import "../../assets/scss/mixins/_bemify.scss";

    .content .card__info__title {
        margin-top: 0.75em !important;
        font-family: $header;
        font-weight: 600;
        font-size: 2em;
    }

    @include block('card') {
        .media {
            display: contents;
        }
        @include element('info') {
            @include element('label') {
                float: left;
                margin-right: 10px;
                display: block;
                width: 100%;
                margin-bottom: 5px !important;
                &.top {
                    margin-bottom: 0px !important;
                }
            }
            @include element('title') {
                line-height: 1.2em;
            }
            @include element('description') {
                line-height: 1.7em;
            }
        }
        @include element('image') {
            img {
                max-width: 200px;
                max-height: 200px;
            }
        }
    }
    .mobile-media {
        display: block !important;
        @media screen and (min-width: 600px) {
            display: none !important;
        }
        .media-center img {
            max-width: 200px;
        }
    }
    .desktop-media {
        display: none !important;
        @media screen and (min-width: 601px) {
            display: block !important;
        }
    }

    .tag__text, .tag__contact {
        text-decoration: none;
        color: $blue--dark;
    }
    .gov-tag {
        margin-bottom: 0px !important;
    }

    .hide-until-loaded {
        display: none;
    }
    .section__alt {
        margin-top: 15px;
    }
    .mb-30 {
        margin-bottom: 30px;
    }
    .mb-5 {
        margin-bottom: 5px;
    }
</style>
